export const LOADING = "LOADING";
export const LOADING_SELECT = "LOADING COURSE";
export const FETCH_SUCCESS_AD = "FETCH_SUCCESS AD";
export const FETCH_FAIL_AD = "FETCH_FAIL AD";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_FAIL = "FETCH_FAIL";
export const SELECT_SUCCESS = "FETCH_SUCCESS";
export const SELECT_FAIL = "FETCH_FAIL";
export const ADD_LOADING = "ADD_LOADING";
export const ADD_SUCCESS = "ADD_SUCCESS";
export const ADD_FAILED = "ADD_FAIL";
export const DEL_SUCCESS = "DEL_SUCCESS";
export const DEL_FAILED = "DEL_FAIL";
export const UPDATE_LOADING = "UPDATE_LOADING";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILED = "UPDATE_FAIL";
