export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILED = "UPDATE_FAILED";
export const LOADING_USER = "LOADING";

export const ALL_SUCCESS = "GET ALL USERS SUCCESS";
export const ALL_FAILED = "GET ALL USERS FAILED";
export const LOADING_USERS = "LOADING USERS";

export const UPDATE_SUBS_LOADING = "UPDATE SUBS LOADING";
export const UPDATE_SUBS_SUCCESS = "UPDATE SUBS SUCCESS";
export const UPDATE_SUBS_FAILED = "UPDATE SUBS FAIL";

export const SEND_LOADING = "CONTACT US LOADING";
export const SEND_SUCCESS = "CONTACT US SUCCESS";
export const SEND_FAILED = "CONTACT US FAIL";
