export const LOADING_SUBS = "LOADING SUBS";
export const GET_SUBS = "GET SUBS SUCCESS";
export const GET_FAIL = "GET SUBS FAIL";
export const GET_SUBS_AD = "GET SUBS SUCCESS AD";
export const GET_FAIL_AD = "GET SUBS FAIL AD";
export const ADD_SUBS_LOADING = "ADD SUBS LOADING";
export const ADD_SUBS_SUCCESS = "ADD SUBS SUCCESS";
export const ADD_SUBS_FAILED = "ADD SUBS FAIL";
export const DEL_SUBS_SUCCESS = "DEL SUBS SUCCESS";
export const DEL_SUBS_FAILED = "DEL SUBS FAIL";
export const UPDATE_SUBS_LOADING = "UPDATE SUBS LOADING";
export const UPDATE_SUBS_SUCCESS = "UPDATE SUBS SUCCESS";
export const UPDATE_SUBS_FAILED = "UPDATE SUBS FAIL";
export const PAYMENT_LOADING = "PAYMENT LOADING";
export const PAYMENT_SUCCESS = "PAYMENT SUCCESS";
export const PAYMENT_FAILED = "PAYMENT FAIL";
